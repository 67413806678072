<template>
  <button @click="click">{{ title }}</button>
</template>

<script>
export default {
  name: "DefaultButton",
  props: {
    title: {
      type: String,
      default: "Click me!",
    },
    click: {
      type: Function,
      default: () => console.log("user clicked button"),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Buttons";
</style>
