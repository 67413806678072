<template>
  <div class="panel-with-table-container">
    <Panel :title="panelTitle">
      <Table :headers="table.headers" :rows="table.rows" />
    </Panel>
  </div>
</template>

<script>
export default {
  name: "PanelWithTable.vue",
  props: {
    panelTitle: {
      type: String,
      default: "",
    },
    table: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
