<template>
  <div class="core-button-base">
    <component :is="renderComponent" v-bind="$props" />
  </div>
</template>

<script>
import ButtonDefault from "./variants/DefaultButton";
export default {
  name: "CoreButtonBase",
  components: {
    ButtonDefault,
  },
  props: {
    variant: {
      type: String,
      default: "default",
    },
  },
  computed: {
    renderComponent() {
      if (this.variant !== "default") {
        return this.variant;
      }
      return "DefaultButton";
    },
  },
};
</script>

<style scoped></style>
