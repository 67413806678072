<template>
  <CoreButtonBase variant="SecondaryButton" v-bind="$props" />
</template>

<script>
import CoreButtonBase from "../../../CoreFormElements/CoreButton/CoreButtonBase";
export default {
  name: "SecondaryButtonLink",
  components: { CoreButtonBase },
};
</script>

<style scoped></style>
