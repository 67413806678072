<template>
  <div class="full-width-picture-container">
    <img :src="picture" alt="Placeholder Image" />
  </div>
</template>

<script>
export default {
  name: "FullWidthPicture",
  props: {
    picture: {
      type: String,
      default: "https://via.placeholder.com/150",
    },
  },
};
</script>

<style scoped></style>
