<template>
  <Markdown
    :id="id"
    :class="computedClass"
    :color="color"
    :content="text"
    :component="currentComponent"
    inline
  />
</template>

<script>
export default {
  name: "Heading",
  props: {
    id: {
      type: String,
    },
    text: {
      type: String,
    },
    size: {
      type: Number,
    },
    color: {
      type: String,
    },
    component: {
      String,
    },
  },
  computed: {
    currentComponent() {
      return this.component || "h2";
    },
    computedClass() {
      return {
        h1Level: this.size === 1,
        h2Level: this.size === 2,
        h3Level: this.size === 3,
        h4Level: this.size === 4,
        h5Level: this.size === 5,
        h6Level: this.size === 6,
      };
    },
  },
};
</script>

<style scoped lang="scss">
h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
  margin: theme-spacing(2) 0;
}

.h1Level {
  @include h1;
}

.h2Level {
  @include h2;
}

.h3Level {
  @include h3;
}

.h4Level {
  @include h4;
}

.h5Level {
  @include h5;
}
</style>
