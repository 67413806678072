<template>
  <div class="table-container">
    <table class="table">
      <template v-if="headers">
        <thead>
          <th v-for="(header, index) in headers" :key="`header-${index}`">
            {{ header }}
          </th>
        </thead>
      </template>
      <tbody>
        <tr v-for="(row, index) in rows" :key="`row-${index}`">
          <td v-for="(column, index2) in row.columns" :key="`column-${index2}`">
            {{ column }}
          </td>
          <td><Badge title="In Progress" /></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;

  &.table {
    width: 100%;
    max-width: 100%;

    & > tbody > tr > td,
    & > tfoot > tr > td {
      padding: 8px;
      line-height: 1.42857143;
      vertical-align: middle;
      border-top: 1px solid #ddd;
    }

    thead {
      font-weight: bold;
    }
  }
}
</style>
