<template>
  <div class="panel" :class="`panel-${panelStyle}`">
    <div class="panel-heading">
      <div class="panel-title">
        <h3 class="panel-title">{{ title }}</h3>
      </div>
    </div>
    <div class="panel-body">
      <template v-if="body">
        {{ body }}
      </template>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Panel",
  props: {
    title: {
      default: "",
    },
    body: {
      default: "",
    },
    panelStyle: {
      default: "default",
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  margin-bottom: 21px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.1);

  .panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
  }

  & > .panel-heading {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #ddd;
  }

  .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 17px;
    color: inherit;
  }

  .panel-body {
    padding: 15px;
  }

  &.panel-default {
    border-color: #ddd;
  }
}
</style>
